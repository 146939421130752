import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface Image {
  url: string;
}

interface ImageSliderProps {
  images: Image[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="w-full lg:flex lg:space-x-4">
      <div className="relative w-full lg:w-3/4 h-[300px] md:h-[400px] lg:h-[500px]">
        <img 
          src={images[currentIndex].url} 
          alt={`Slide ${currentIndex}`}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-1/2 left-4 transform -translate-y-1/2">
          <button 
            onClick={goToPrevious} 
            className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-md shadow-md hover:bg-opacity-70"
            style={{ transition: 'transform 0.2s', fontSize: '1.1rem' }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        <div className="absolute top-1/2 right-4 transform -translate-y-1/2">
          <button 
            onClick={goToNext} 
            className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-md shadow-md hover:bg-opacity-70"
            style={{ transition: 'transform 0.2s', fontSize: '1.1rem' }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      <div className="mt-4 lg:mt-0 lg:w-1/4 lg:h-[500px] h-auto overflow-y-auto lg:overflow-x-hidden overflow-x-auto flex lg:flex-col lg:space-y-2 space-x-2 lg:space-x-0">
        {images.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`flex-shrink-0 cursor-pointer ${currentIndex === slideIndex ? 'border-2 border-blue-500' : ''}`}
          >
            <img 
              src={slide.url} 
              alt={`Thumbnail ${slideIndex}`}
              className="w-24 h-24 lg:w-full lg:h-32 object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;