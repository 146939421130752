import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { numberFormat, Property } from '../../utils/properties';
import useThemeStore from '../../store/themeStore';
import { ThemeProps } from '../../utils/theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useTokenStore from "../../store/store";

interface ListViewProps {
  properties: Property[];
  onRequestSort: (key: keyof Property) => void;
  onShareClick: (property: Property) => void;
}

const ListView: React.FC<ListViewProps> = ({ properties, onRequestSort, onShareClick }) => {
  const navigate = useNavigate();
  const getToken = useTokenStore((state) => state.token);
  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  const handleCardClick = (id: string) => {
    if (getToken) {
      navigate(`/listing/${id}`);
    } else {
      sessionStorage.setItem('intendedPropertyId', id);
      navigate('/login');
    }
  };

  useEffect(() => {
    const intendedPropertyId = sessionStorage.getItem('intendedPropertyId');
    if (intendedPropertyId && getToken) {
      navigate(`/listing/${intendedPropertyId}`);
      sessionStorage.removeItem('intendedPropertyId');
    }
  }, [getToken, navigate]);

  const handleShareClick = (e: React.MouseEvent, property: Property) => {
    e.stopPropagation();
    onShareClick(property);
  };

  const formatAddress = (address: string) => {
    return address.trim().replace(/,\s*$/, '');
  };

  return (
    <div className="w-full" style={{ width: '97%', margin: '0 auto' }}>
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-2 px-4 text-left font-semibold w-2/5">Property</th>
            <th className="py-2 pr-9 text-right font-semibold w-[15%]">Price</th>
            <th className="py-2 pr-9 text-right font-semibold w-[15%]">Valuation</th>
            <th className="py-2 pr-9 text-right font-semibold w-[15%]">Investment</th>
            <th className="py-2 px-4 text-right font-semibold w-[15%]">Rental Income</th>
            <th className="py-2 px-4 w-10"></th>
          </tr>
        </thead>
        <tbody>
          {properties.map((property) => (
            <tr key={property._id} className="bg-white mb-4">
              <td colSpan={6} className="p-0">
                <div 
                  className="border border-gray-300 rounded-lg shadow-md hover:shadow-xl transition duration-150 ease-in-out mb-4 cursor-pointer"
                  onClick={() => handleCardClick(property._id)}
                >
                  <div className="bg-gray-100 p-2 rounded-t-lg">
                    <div className="flex justify-between items-center">
                      <span className="font-bold py-1 px-3 rounded-md">
                        £{numberFormat(property.price as unknown as number)}
                      </span>
                      <div className="flex items-center">
                        {property.reserved && (
                          <span className="text-xs font-bold text-white py-1 px-2 rounded-md mr-2"
                            style={{
                              background: `${getThemes?.color2}`,
                              color: `${getThemes?.txtColor}`,
                            }}>
                            Reserved
                          </span>
                        )}
                        <span className="font-bold">
                          {property.rentGrossYield}% Gross Yield
                        </span>
                      </div>
                    </div>
                  </div>
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="py-4 px-4 w-2/5">
                          <div className="flex items-start">
                            <LazyLoadImage
                              src={property.images && property.images[0] ? property.images[0].url : ""}
                              width={96}
                              height={96}
                              className="w-36 h-24 object-cover rounded mr-4"
                              alt={property.propertyOf}
                            />
                            <div className="flex flex-col">
                              <p className="font-semibold text-sm mb-1">{formatAddress(property.propertyOf)}</p>
                              <div className="flex flex-wrap gap-1 mb-1">
                                {property.tenureType && (
                                  <span className="text-xs font-bold text-white py-0.5 px-1.5 rounded-md"
                                    style={{
                                      background: `${getThemes?.color1}`,
                                      color: `${getThemes?.txtColor}`,
                                    }}>
                                    {property.tenureType}
                                  </span>
                                )}
                                {property.occupancy && property.occupancy.toLowerCase() === 'tenanted' && (
                                  <span className="text-xs font-bold text-white py-0.5 px-1.5 rounded-md"
                                    style={{
                                      background: `${getThemes?.color1}`,
                                      color: `${getThemes?.txtColor}`,
                                    }}>
                                    Tenanted
                                  </span>
                                )}
                                {property.discountToMarket > 0 && (
                                  <span 
                                    className="text-xs font-bold text-white py-0.5 px-1.5 rounded-md"
                                    style={{
                                      background: `${getThemes?.color1}`,
                                      color: `${getThemes?.txtColor}`,
                                    }}
                                  >
                                    {property.discountToMarket}% Discount
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-15 text-right font-semibold w-[15%]">£{numberFormat(property.price as unknown as number)}</td>
                        <td className="py-4 pr-15 text-right font-semibold w-[15%]">From £{numberFormat(property.estimatedValue as unknown as number)}</td>
                        <td className="py-4 pr-5 text-right font-semibold w-[15%]">£{numberFormat(property.investment as unknown as number)}</td>
                        <td className="py-4 px-4 text-right font-semibold w-[15%]">Up To £{numberFormat(property.averageRent as unknown as number)}</td>
                        <td className="py-4 px-4 text-center w-10">
                          <FontAwesomeIcon 
                            icon={faShareAlt} 
                            onClick={(e) => handleShareClick(e, property)} 
                            className="cursor-pointer" 
                            style={{ transition: 'transform 0.2s', fontSize: '1.1rem' }}
                            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListView;