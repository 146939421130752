import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faCopy, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

interface PropertyShareIconsProps {
  propertyId: string;
}

const PropertyShareIcons: React.FC<PropertyShareIconsProps> = ({ propertyId }) => {
  const [shareExpanded, setShareExpanded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (shareExpanded) {
      const timer = setTimeout(() => {
        setShareExpanded(false);
      }, 20000); // 20 seconds
      return () => clearTimeout(timer);
    }
  }, [shareExpanded]);

  useEffect(() => {
    if (modalVisible) {
      const timer = setTimeout(() => {
        setModalVisible(false);
      }, 1000); // 1 second
      return () => clearTimeout(timer);
    }
  }, [modalVisible]);

  const toggleShareOptions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShareExpanded(!shareExpanded);
  };

  const handleCopyLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    const link = `${window.location.origin}/listing/${propertyId}`;
    navigator.clipboard.writeText(link);
    setModalVisible(true); // Show the modal
  };

  const handleWhatsAppShare = (e: React.MouseEvent) => {
    e.stopPropagation();
    const link = `${window.location.origin}/listing/${propertyId}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(link)}`, '_blank');
  };

  const handleEmailShare = (e: React.MouseEvent) => {
    e.stopPropagation();
    const link = `${window.location.origin}/listing/${propertyId}`;
    window.location.href = `mailto:?subject=Check out this property&body=${encodeURIComponent(link)}`;
  };

  const iconStyle = {
    transition: 'transform 0.2s',
    fontSize: '1.2rem',
    marginLeft: '8px',
  };

  return (
    <div className="flex items-center relative" onClick={(e) => e.stopPropagation()}>
      <button
        className="text-blue-500 hover:text-blue-700 focus:outline-none"
        style={{
          ...iconStyle,
          marginLeft: '0',
        }}
        onClick={toggleShareOptions}
        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        <FontAwesomeIcon icon={faShareAlt} />
      </button>

      {shareExpanded && (
        <div className="flex items-center ml-4">
          <div className="mx-4 h-6 border-l border-gray-300"></div>
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            style={iconStyle}
            onClick={handleCopyLink}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
          <div className="mx-4 h-6 border-l border-gray-300"></div>
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            style={iconStyle}
            onClick={handleWhatsAppShare}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </button>
          <div className="mx-4 h-6 border-l border-gray-300"></div>
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            style={iconStyle}
            onClick={handleEmailShare}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </button>
          <div className="mx-4 h-6 border-l border-gray-300"></div>
        </div>
      )}

      {modalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg" style={{ backgroundColor: "white" }}>
          <h2 className="text-xl font-bold mb-4">Link Copied</h2>
            <p>This properties link has been copied to your clipboard</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyShareIcons;
