import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
  faBed,
  faShareAlt,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTokenStore from "../../store/store";
import { numberFormat } from "../../utils/properties";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImage from '../../images/photo-coming-soon-300x300-1-square.jpg'; 

interface PropertyCardProps {
  id: string;
  discount: string;
  image: string;
  streetName: string;
  price: string;
  bedrooms: string;
  value: string;
  rent: string;
  netCashFlow: string;
  grossYield: string;
  strategy?: string;
  discountLabel?: string;
  available: boolean;
  tenureType?: string;
  occupancy?: string;
  floorsqft?: string;
  region?: string;
  investment?: string;
  isFeatured?: boolean;
  onShareClick: () => void;
  onClick: () => void;
}

const PropertyCard = ({
  id,
  discount,
  available,
  image,
  streetName,
  price,
  bedrooms,
  value,
  rent,
  grossYield,
  strategy,
  occupancy,
  tenureType,
  floorsqft,
  region,
  investment,
  onShareClick,
  isFeatured = false,
  onClick,
}: PropertyCardProps) => {
  const getToken = useTokenStore((state) => state.token);
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent) => {
    if (!getToken) {
      e.preventDefault();
      sessionStorage.setItem('intendedPropertyId', id); // Save the property ID
      navigate('/login'); // Navigate to the login page
    } else {
      onClick(); // Navigate directly to the property page
    }
  };

  console.log("PropertyCard props:", { 
    id, discount, available, streetName, price, occupancy, tenureType
  });

  const roundedYield = Math.ceil(Number(grossYield));
  const roundedInvestment = Math.round(Number(investment));

  // Format strategy display
  const displayStrategy = strategy === "Housing Association" ? "H.A" : (strategy === "Serviced Accommodation" ? "Serviced" : strategy);

  return (
    <Link to={getToken ? `/listing/${id}` : `/login`} onClick={handleClick}>
      <div onClick={onClick} className="cursor-pointer">
      <div className={`max-w-7xl mx-auto mb-5 ${isFeatured ? 'ring-2 ring-yellow-400' : ''}`}>
        <div className="border rounded-xl relative shadow-md hover:shadow-xl transition duration-150 ease-in-out">
          {/* Top Bar with Price and Yield */}
          <div className="flex justify-between items-center p-2 bg-gray-100 rounded-t-xl"
            style={{
              background: `${getThemes && getThemes.color1}`,
              color: `${getThemes && getThemes.txtColor}`,
            }}>
            <span className="font-bold bg-green-200 text-green-700 py-1 px-3 rounded-md">
              £{numberFormat(price as unknown as number)}
            </span>
            <span className="font-bold">
              {roundedYield}% {strategy === "Housing Association" ? "Net" : "Gross"} Yield
            </span>
          </div>

          {/* Property Image */}
          <div className="relative">
            <LazyLoadImage
              src={image}
              width={150}
              height={150}
              className="h-[250px] w-full object-cover"
              alt="Property Image"
            />
          </div>

          {/* Property Details */}
          <div className="p-4">
            <h3 className="font-roboto font-medium text-lg mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
              {streetName}
            </h3>
            <p className="text-gray-600 mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
              {region && region !== "false" ? region : <span>&nbsp;</span>}
            </p>
            
            <div className="flex flex-wrap gap-2 mb-2 overflow-hidden">
              {!available && (
                <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                  style={{
                    background: `${getThemes && getThemes.color1}`,
                    color: `${getThemes && getThemes.txtColor}`,
                  }}>
                  Reserved
                </span>
              )}
              {parseFloat(discount) > 0 && (
                <span 
                  className="text-xs font-bold text-white py-1 px-2 rounded-md"
                  style={{
                    background: `${getThemes && getThemes.color1}`,
                    color: `${getThemes && getThemes.txtColor}`,
                  }}
                >
                  {discount}% Discount
                </span>
              )}
              {occupancy && occupancy.toLowerCase() === 'tenanted' && (
                <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                  style={{
                    background: `${getThemes && getThemes.color1}`,
                    color: `${getThemes && getThemes.txtColor}`,
                  }}>
                  Tenanted
                </span>
              )}
              {tenureType && (
                <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                  style={{
                    background: `${getThemes && getThemes.color1}`,
                    color: `${getThemes && getThemes.txtColor}`,
                  }}>
                  {tenureType}
                </span>
              )}
              {displayStrategy && (
                <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                  style={{
                    background: `${getThemes && getThemes.color1}`,
                    color: `${getThemes && getThemes.txtColor}`,
                  }}>
                  {displayStrategy}
                </span>
              )}
            </div>
            <ul className="mt-3">
              <li className="flex justify-between py-1">
                <span>Zoopla Valuation </span>
                <span className="font-bold">£{numberFormat(value as unknown as number)}</span>
              </li>
              <li className="flex justify-between py-1">
                <span>Total Investment</span>
                <span className="font-bold">£{numberFormat(roundedInvestment)}</span>
              </li>
              <li className="flex justify-between py-1">
                <span>Rental Income (pcm)</span>
                <span className="font-bold">£{numberFormat(rent as unknown as number)}</span>
              </li>
            </ul>

            <div className="flex justify-between items-center mt-3">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faBed} />
                <span>{bedrooms}</span>
              </div>
              {floorsqft && (
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faRulerCombined} />
                  <span>{floorsqft}</span>
                </div>
              )}
              <div className="flex items-center gap-2">
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onShareClick();
                  }} 
                  className="focus:outline-none"
                  style={{ 
                    transition: 'transform 0.2s', 
                    fontSize: '1.1rem' 
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Link>
  );
};

export default PropertyCard;
