import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import ReviewStars from "../components/ReviewStars";
import Rightarrow from "../images/icons/arrow-right-solid.svg"; 
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import miniCityscape from "../images/vesta/mini-cityscape.svg";
import image1 from "../images/vesta/Conveyancing.png";
import image2 from "../images/vesta/StampDuty.svg";
import image3 from "../images/vesta/large-cityscape-crop.png";
import image4 from "../images/vesta/large-cityscape-removebg.png";
import { Link } from 'react-router-dom';

function WhiteLabel() {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollOffset, setScrollOffset] = useState(-150);
  const MOBILE_OFFSET = -340;  // Adjust this value as needed
  const { ref: sectionRef1, inView: inView1 } = useInView({ triggerOnce: false });
  const { ref: sectionRef2, inView: inView2 } = useInView({ triggerOnce: false });
  const { ref: sectionRef3, inView: inView3 } = useInView({ triggerOnce: false });
  const { ref: sectionRef4, inView: inView4 } = useInView({ triggerOnce: false });
  const { ref: buttonRef1, inView: buttonInView1 } = useInView({ triggerOnce: false });
  const { ref: buttonRef2, inView: buttonInView2 } = useInView({ triggerOnce: false });
  const { ref: buttonRef3, inView: buttonInView3 } = useInView({ triggerOnce: false });

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      setScrollOffset(newWidth <= 768 ? MOBILE_OFFSET : -30);
    };



    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial values

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const svgStyle = {
    fill: getThemes && getThemes.color2 // Apply your theme color here
  };

  return (
    <div className="Home bg-primarycolor pt-28" style={{ background: getThemes && getThemes.color1 }}>
      <Helmet>
        <title>White Label</title>
        <meta name="description" content="White Label" />
      </Helmet>

      <style>
        {`
          @keyframes slide-in-right {
            from {
              opacity: 0;
              transform: translateX(100%);
            }
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }

          @keyframes slide-in-left {
            from {
              opacity: 0;
              transform: translateX(-100%);
            }
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }

          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
            100% {
              transform: scale(1);
            }
          }

          .slide-in-right {
            animation: slide-in-right 1s forwards;
          }

          .slide-in-left {
            animation: slide-in-left 1s forwards;
          }

          .pulse {
            animation: pulse 2s;
          }

          .banner-text-container {
            text-align: left;
          }

          .banner-buttons-container {
            display: flex;
            gap: 1rem;
          }

          /* Media Query for Mobile Devices */
          @media (max-width: 768px) {
            .banner-text-container {
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0 20px;
            }

            .banner-text-container h3,
            .banner-text-container p {
              text-align: center;
            }

            .banner-buttons-container {
              flex-direction: column;
              width: 100%;
            }

            .banner-button {
              width: 100%;
            }
          }
        `}
      </style>

      {/* Banner */}
      <div className="relative w-full" style={{ 
        backgroundImage: `url(${backgroundPattern})`, 
        backgroundRepeat: 'repeat', 
        backgroundSize: '100px 100px' 
      }}>
        <div className="absolute bottom-0 right-0 lg:w-2/5 w-full lg:h-auto h-auto xl:w-1/3 2xl:w-3/5" style={{ height: 'auto', minHeight: '100px', willChange: 'transform' }}>
          <img
            src={miniCityscape}
            className="w-full h-auto"
            alt="Mini Cityscape"
            style={{ maxWidth: '100%', maxHeight: '100%', willChange: 'transform' }}
          />
        </div>
        <div className="max-w-7xl m-auto grid lg:grid-cols-5 grid-cols-1 lg:pt-16 pt-5 pb-20 gap-x-4 justify-center items-center relative">
          <div className="p-5 col-span-3 relative z-10 banner-text-container">
            <h3 className="lg:text-5xl text-xl font-poppins lg:font-semibold font-medium text-white-f2f9fa lg:pb-7 pb-3 lg:leading-snug lg:pr-12 lg:tracking-wide tracking-wider">
              Build a high yielding residential property portfolio with ease
            </h3>
            <p className="text-base font-poppins font-normal text-white-f2f9fa leading-7 tracking-wide lg:pb-3 pb-3">
              We use innovative, proprietary technology to help ambitious
              investors build, grow and manage high yielding, sustainable
              property portfolios. Our hassle-free investment experience allows
              you to sit back and watch your wealth grow.
            </p>
            <div className="banner-buttons-container">
              <Link className="text-white-f2f9fa banner-button" to="/listing">
                <div
                  className="py-2.5 px-6 rounded-full bg-btnscolor hover:bg-white-f2f9fa hover:text-white-f2f9fa transition ease-in-out delay-150 hover:scale-110 duration-300"
                  style={{ background: getThemes && getThemes.color2 }}
                >
                  Search Properties
                </div>
              </Link>
              <ScrollLink 
                to="section1" 
                smooth={true} 
                duration={700} 
                offset={scrollOffset}
                className="text-white-f2f9fa cursor-pointer banner-button"
              >
                <div className="flex gap-x-3 px-6 py-2.5 rounded-full transition ease-in-out delay-150 hover:scale-110 duration-300 justify-center">
                  Learn More
                  <img src={Rightarrow} className="w-6 h-6" style={svgStyle} alt="Right arrow"/>
                </div>
              </ScrollLink>
      </div>
    </div>
  </div>
</div>
      {/* <<============Video============>> */}
      <div className="w-full m-auto bg-white-f2f9fa">
        <div className="max-w-7xl m-auto py-16">
          <div className="m-auto">
            <h3 className="text-4xl font-poppins font-semibold text-primarycolor w-full text-center pb-5">
              How it works...
            </h3>
            <div className="flex flex-wrap justify-center lg:pr-14 px-5" style={{ position: 'relative', marginBottom: '4rem' }}>
              {windowWidth >= 1270 && (
                <div style={{ position: 'absolute', bottom: '-5%', right: '25%', backgroundColor: getThemes.color1, width: '50%', height: '100%', zIndex: 1, borderBottomLeftRadius: '1%', borderBottomRightRadius: '1%', borderTopRightRadius: '1%' }}></div>
              )}
              <iframe
                title="vimeo-player"
                src="https://www.youtube.com/embed/l79nMEcEN2Q?rel=0"
                width="640"
                height="360"
                frameBorder={0}
                allowFullScreen
                style={{ position: 'relative', zIndex: 2 }}
              ></iframe>
            </div>
          </div>
        </div>

        <div className="relative w-full" style={{ 
          backgroundImage: `url(${backgroundPattern})`, 
          backgroundRepeat: 'repeat', 
          backgroundSize: '75px 75px' 
        }}>
          <div className="max-w-7xl m-auto py-16 px-4 md:px-8">
    <div className="flex flex-wrap items-center py-5">
        <div className="lg:w-1/2 w-full">
            <img
                src={image1}
                className="w-85 m-auto"
                alt="image1"
            />
        </div>
        <div id="section1" ref={sectionRef1} className={`lg:w-1/2 w-full font-poppins lg:text-left text-center pt-4 pb-4 lg:pr-10 px-5 border-gray-300 border rounded-xl shadow-lg hover:shadow-xl transition duration-150 ease-in-out ${inView1 ? 'slide-in-right' : ''}`} style={{backgroundColor: "white" }}>
            <h3 className="text-2xl tracking-wide pb-3">
                Select your budget, preferred locations and yield expectations
            </h3>
            <p className="text-base leading-7 tracking-wide pb-3">
                Using innovative, property technology and data-analysis tools
                we’ll instantly source a fully vetted list of profitable
                investment opportunities that match your search criteria.
                These will be packaged up into an easy-to-understand document
                ready for you to approve.
            </p>
            <p className="text-sm leading-7 tracking-wide">
                Rest assured, all properties go through a full, comprehensive
                due diligence process. You’ll receive a full financial
                break-down, comparable properties in the market, information
                about the local area, refurbishment requirements (if any) and
                estate agent valuations for sales and lettings.
            </p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl m-auto py-16 px-4 md:px-8">
          <div className="flex flex-wrap items-center py-5">
              <div ref={sectionRef2} className={`lg:w-1/2 w-full font-poppins lg:text-left text-center pt-4 pb-4 lg:pr-10 px-5 border-gray-300 border rounded-xl shadow-lg hover:shadow-xl transition duration-150 ease-in-out ${inView2 ? 'slide-in-left' : ''}`} style={{backgroundColor: "white" }}>
                  <h3 className="text-2xl tracking-wide pb-3">
                      Watch your residential property portfolio, and wealth, grow!
                  </h3>
                  <p className="text-base leading-7 tracking-wide pb-3">
                      There is no limit to the number of properties you can hold
                      within your portfolio. You’ll always own the assets, fully
                      benefit from the capital appreciation, as well as the cashflow
                      from renting.
                  </p>
                  <p className="text-sm leading-7 tracking-wide">
                      Ready to start your property investment journey? Get started
                      today to tell us your search criteria and we’ll share a fully
                      bespoke, curated list of fully vetted properties that match
                      your budget, location and profitability requirements.
                  </p>
              </div>
              <div className="lg:w-1/2 w-full">
                  <img
                      src={image3}
                      className="w-85 m-auto"
                      alt=""
                  />
              </div>
          </div>
      </div>

      <div className="relative w-full" style={{ 
        backgroundImage: `url(${backgroundPattern})`, 
        backgroundRepeat: 'repeat', 
        backgroundSize: '75px 75px' 
    }}>
        <div className="max-w-7xl m-auto py-16 px-4 md:px-8">
            <div className="flex flex-wrap items-center">
                <div className="lg:w-1/2 w-full">
                    <img
                        src={image2}
                        className="w-85 m-auto"
                        alt=""
                    />
                </div>
                <div ref={sectionRef3} className={`lg:w-1/2 w-full font-poppins lg:text-left text-center pt-4 pb-4 lg:pr-10 px-5 border-gray-300 border rounded-xl shadow-lg ${inView3 ? 'slide-in-right' : ''}`} style={{backgroundColor: "white" }}>
                    <h3 className="text-2xl tracking-wide pb-3">
                        The entire process is taken care of for you - from sourcing to
                        letting
                    </h3>
                    <p className="text-base leading-7 tracking-wide pb-3">
                        The entire deal will be managed by a team of experts. Finding
                        the properties and carrying out full due diligence, managing
                        the buying process to get you the best deal, pricing and
                        managing the refurbishment, sourcing tenants, letting
                        management, even your bookkeeping and accounts.
                    </p>
                    <p className="text-sm leading-7 tracking-wide">
                        You’ll be kept up to date throughout the entire process – as
                        little or as much as you want to be. Building a residential
                        property portfolio is not only easy, but enjoyable. We’ll help
                        you enjoy a hassle-free investment journey which allows you to
                        divert your attention elsewhere.
                    </p>
                </div>
            </div>
        </div>

          <div className="justify-center flex pt-14">
            <Link to="/listing">
              <div ref={buttonRef1} className={`py-2.5 w-56 rounded-full bg-btnscolor hover:bg-white-f2f9fa hover:text-white-f2f9fa  transition ease-in-out delay-150 hover:scale-110 duration-300 ${buttonInView1 ? 'pulse' : ''}`} style={{ background: getThemes && getThemes.color2 }}>
                Learn More
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* <<============4 colr============>> */}
      <div className="w-full m-auto bg-white-f2f9fa">
        <div className="max-w-7xl m-auto lg:py-24 py-14 text-primarycolor font-poppins lg:px-0 px-5">
        <h3 className="lg:text-4xl text-2xl font-semibold text-center leading-tight pb-6">
            Buy-to-let investments -<br></br>a reliable way to get rich while
            you sleep
          </h3>
          <h5 className="lg:text-2xl text-lg font-medium tracking-wide text-center">
            Buy-to-let investment properties are among the most reliable and
            profitable investments.
          </h5>
          <img
            src={image4}
            className="h-50 m-auto"
            alt=""
          />
          <div className="pt-14">
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-5 m-auto">
              {[
                {
                  title: "Invest In A Stable Market",
                  description: "48% of rental properties are owned by portfolio landlords (10 or more properties)"
                },
                {
                  title: "Benefit From Predictability",
                  description: "Investing in residential property is less volatile than other markets"
                },
                {
                  title: "See Double Profitability",
                  description: "Receive rent monthly, but also an increase in property value over-time"
                },
                {
                  title: "Little Effort Required",
                  description: "We take care of everything so you can divert your attention elsewhere"
                }
              ].map((item, index) => (
                <div key={index} className="flex flex-col justify-center p-6 rounded-xl border-gray-300 border shadow-xl transition duration-150 ease-in-out hover:shadow-2xl" style={{backgroundColor: "white"}}>
                  <h3 className="font-roboto text-xl font-medium text-center mb-4">
                    {item.title}
                  </h3>
                  <p className="font-roboto text-base font-normal tracking-wide text-center">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex justify-center pt-10">
              <div ref={buttonRef2} className={`py-2.5 w-56 rounded-full bg-btnscolor hover:bg-white-f2f9fa hover:text-white-f2f9fa transition ease-in-out delay-150 hover:scale-110 duration-300 text-center ${buttonInView2 ? 'pulse' : ''}`} style={{ background: getThemes && getThemes.color2 }}>
                Get Started
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full m-auto bg-white-f2f9fa">
        <div className="relative w-full" style={{ 
            backgroundImage: `url(${backgroundPattern})`, 
            backgroundRepeat: 'repeat', 
            backgroundSize: '75px 75px' 
          }}>
          <div className="max-w-7xl m-auto lg:py-24 py-14 text-primarycolor font-poppins px-5">
            <h3 className="lg:text-4xl text-2xl font-semibold text-center leading-tight pb-6">
              What do our portfolio landlords think?
            </h3>
            <div className="pt-6">
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 m-auto">
                {[
                  {
                    text: "This site takes the stress out of finding properties for myself. The team is easy to talk to and always quick to respond. I have purchased multiple properties and will continue to do so in the future.",
                    author: "Mr R"
                  },
                  {
                    text: "This business is like a family who welcome you into the fold. They only work with trusted parties so you're coming into a group with a wealth of knowledge and trust. One of the best decisions I've ever made.",
                    author: "Mrs M"
                  },
                  {
                    text: "I sourced my first deal as a property newbie and the service I received was exceptional. I was led through the entire process step-by-step and the communication was always thorough and consistent. Highly recommend.",
                    author: "Mr A. K"
                  }
                ].map((review, index) => (
                  <div
                    key={index}
                    ref={index === 0 || index === 2 ? sectionRef4 : null}
                    className={`flex flex-col justify-center p-6 rounded-xl border-gray-300 border shadow-xl transition duration-150 ease-in-out hover:shadow-2xl ${
                      (index === 0 && inView4) ? 'slide-in-left' : 
                      (index === 2 && inView4) ? 'slide-in-right' : ''
                    }`}
                    style={{ backgroundColor: "white" }}
                  >
                    <h3 className="font-poppins text-sm font-normal leading-6 mb-4 text-center">
                      {review.text}
                    </h3>
                    <ReviewStars />
                    <p className="font-roboto text-base font-normal tracking-wide text-center mt-4">
                      {review.author}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full m-auto bg-[#F8F8F9]">
        <div className="max-w-7xl m-auto py-24 text-primarycolor font-poppins lg:px-0 px-5">
          <h3 className="lg:text-4xl text-2xl font-semibold text-center leading-tight pb-6">
            Ready to get started?
          </h3>
          <h5 className="text-xl font-normal tracking-wide text-center">
            Join our investor community today and starting browsing our
            high-yielding investment opportunities
          </h5>
          <div className="flex justify-center pt-5">
            <div ref={buttonRef3} className={`py-2.5 w-56 rounded-full bg-btnscolor hover:bg-white-f2f9fa hover:text-white-f2f9fa transition ease-in-out delay-150 hover:scale-110 duration-300 text-center ${buttonInView3 ? 'pulse' : ''}`} style={{ background: getThemes && getThemes.color2 }}>
              Sign Up Today
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhiteLabel;