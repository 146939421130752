import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { loginUser, ResultLoginProps } from "../utils/user";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import Footer from "../components/headerFooter/Footer";

type Inputs = {
  email: string;
  password: string;
};

function Userlogin() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<ResultLoginProps>({
    message: "",
    status: 0,
    token: "",
    user: {
      id: "",
      fullname: "",
      email: "",
      userHandle: "",
    },
  });

  const navigate = useNavigate();
  const setToken = useTokenStore((state) => state.setToken);
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    setLoading(true);
    const response = await loginUser(data);
  
    setMessage(response);
    setLoading(false);
  
    if (response.status === 200) {
      setToken(response);
  
      // Delay navigation to allow the modal to be displayed
      setTimeout(() => {
        const intendedPropertyId = sessionStorage.getItem('intendedPropertyId');
        if (intendedPropertyId) {
          sessionStorage.removeItem('intendedPropertyId');
          navigate(`/listing/${intendedPropertyId}`); // Navigate directly to the property page
        } else {
          navigate("/listing"); // Navigate to the listing page
        }
      }, 100); // Delay for modal display
    }
  };

  useEffect(() => {
    const setBodyStyles = () => {
      document.body.style.display = "flex";
      document.body.style.flexDirection = "column";
      document.body.style.minHeight = "100vh";
      document.body.style.margin = "0";
      document.body.style.position = "relative";
      document.body.style.overflow = "auto";
    };

    setBodyStyles();

    return () => {
      document.body.style.display = "";
      document.body.style.flexDirection = "";
      document.body.style.minHeight = "";
      document.body.style.margin = "";
      document.body.style.position = "";
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div
      className="userlogin"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
        overflow: "auto",
      }}
    >
      <Helmet>
        <title>Login | {getThemes && getThemes.name}</title>
      </Helmet>

      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundPattern})`,
          backgroundRepeat: "repeat",
          backgroundSize: "60px 60px",
          zIndex: -1,
        }}
      />

      {/* Fade Overlay */}
      {message.status !== 0 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
            zIndex: 999, // Ensure it's above other content
            opacity: 1,
            transition: "opacity 0.3s ease",
          }}
        />
      )}

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px",
          paddingTop: "160px", // Add top padding for mobile
          paddingBottom: "80px", // Add bottom padding for mobile
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white flex flex-wrap justify-center pt-8 pb-8 px-4"
          style={{
            width: "85%",
            maxWidth: "500px",
            border: "1px solid #ddd",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
            backgroundColor: "white",
            marginBottom: "20px",
          }}
        >
          <div className="w-full">
            <p className="text-3xl font-roboto font-medium pb-8 pt-8 text-center">Login</p>
            {/* Email input */}
            <div className="w-full py-2">
              <label
                htmlFor="email-input"
                className="block text-base font-medium mb-2 text-left"
                style={{
                  color: "black",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Email Address
              </label>
              <input
                type="email"
                id="email-input"
                className="py-3 px-4 block w-full rounded-md text-sm"
                style={{
                  border: "1px solid black",
                  borderRadius: "0.375rem",
                  padding: "0.75rem",
                  color: "#2D3748",
                  backgroundColor: "#FFF",
                }}
                placeholder="Email Address"
                {...register("email", { required: true })}
              />
              <small className="flex justify-start pt-3 text-red-hover-ce312b">
                {errors.email && <span>Email Address is required</span>}
              </small>
            </div>
            {/* Password input */}
            <div className="w-full py-2">
              <label
                htmlFor="password-input"
                className="block text-base font-medium mb-2 text-left"
                style={{
                  color: "black",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Password
              </label>
              <input
                type="password"
                id="password-input"
                className="py-3 px-4 block w-full rounded-md text-sm"
                style={{
                  border: "1px solid black",
                  borderRadius: "0.375rem",
                  padding: "0.75rem",
                  color: "#2D3748",
                  backgroundColor: "#FFF",
                }}
                placeholder="Password"
                {...register("password", { required: true })}
              />
              <small className="flex justify-start pt-3 text-red-hover-ce312b">
                {errors.password && <span>Password is required</span>}
              </small>
            </div>
            {/* Login button and links */}
            <div className="text-center">
              <button
                type="submit"
                style={{
                  width: "30%",
                  background: getThemes.color1,
                  color: getThemes.txtColor,
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  transition: "background 0.3s ease",
                  boxSizing: "border-box",
                }}
                onMouseOver={(e) => (e.currentTarget.style.background = getThemes.color2)}
                onMouseOut={(e) => (e.currentTarget.style.background = getThemes.color1)}
              >
                Log In
                {loading && (
                  <span
                    className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </button>
              <Link
                to="/resetPassword"
                className="w-full inline-flex justify-center gap-2 font-medium tracking-wide hover:underline py-2"
              >
                Lost your password?
              </Link>
              <Link to="/register">
                <button
                  type="button"
                  className="w-full inline-flex justify-center gap-2 font-medium tracking-wide hover:underline py-2 pb-4"
                >
                  Do not have an account? Sign Up Here
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <Footer />

      {/* Welcome Modal
      {message.status === 200 && (
        <div
          className="fixed inset-0 flex items-center justify-center"
          style={{
            zIndex: 1000,
          }}
        >
          <div
            className="bg-white p-6 rounded shadow-md text-center"
            style={{
              width: "90%",
              maxWidth: "400px",
              backgroundColor: "white",
            }}
          >
            <h2 className="text-xl font-bold mb-4">Welcome, {message.user.fullname}</h2>
            <p>You have successfully logged in</p>
          </div>
        </div>
      )}*/}

      {/* Toast Notification */}
      {message.status !== 0 && message.status !== 200 && (
        <div
          className="fixed inset-0 flex items-center justify-center"
          style={{ zIndex: 1000 }}
        >
          <div
            className="bg-white p-6 rounded shadow-md text-center"
            style={{
              width: "90%",
              maxWidth: "400px",
              backgroundColor: "white",
              zIndex: 1001, // Ensure it's above the overlay
            }}
          >
            <h2 className="text-xl font-bold mb-4">{message.message}</h2>
            <button
              onClick={() => setMessage({ ...message, status: 0 })}
              style={{
                width: "30%",
                background: getThemes.color1,
                color: getThemes.txtColor,
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                transition: "background 0.3s ease",
                boxSizing: "border-box",
              }}
              onMouseOver={(e) => (e.currentTarget.style.background = getThemes.color2)}
              onMouseOut={(e) => (e.currentTarget.style.background = getThemes.color1)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Userlogin;
