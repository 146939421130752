import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faStar } from '@fortawesome/free-solid-svg-icons';

interface FloatingActionButtonsProps {
  themeColor: string;
  textColor: string;
}

const FloatingActionButtons: React.FC<FloatingActionButtonsProps> = ({ themeColor, textColor }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const originalButtons = document.getElementById('original-buttons');
      if (originalButtons) {
        const rect = originalButtons.getBoundingClientRect();
        setIsVisible(rect.bottom < 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const openModal = (modalId: string) => {
    const modal = document.getElementById(modalId);
    if (modal) {
      const hsOverlay = (window as any).HSOverlay;
      if (hsOverlay) {
        hsOverlay.open(modal);
      }
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-28 right-8 flex flex-col space-y-4 z-[9999]">
      <div className="relative group">
        <div 
          className="absolute inset-0 rounded-full" 
          style={{ 
            animation: 'slowPing 3s cubic-bezier(0, 0, 0.2, 1) infinite',
            backgroundColor: themeColor, 
            opacity: 0.2 
          }}
        />
        <button
          onClick={() => openModal('hs-slide-down-animation-modal1')}
          className="relative w-14 h-14 rounded-full flex items-center justify-center shadow-lg hover:scale-110 transition duration-300"
          style={{ backgroundColor: themeColor, color: textColor }}
        >
          <FontAwesomeIcon icon={faHandshake} size="lg" />
        </button>
        <div
          className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap"
          style={{ backgroundColor: themeColor, color: textColor }}
        >
          Make Offer
        </div>
      </div>
      <div className="relative group">
        <div 
          className="absolute inset-0 rounded-full" 
          style={{ 
            animation: 'slowPing 3s cubic-bezier(0, 0, 0.2, 1) infinite',
            backgroundColor: themeColor, 
            opacity: 0.2 
          }}
        />
        <button
          onClick={() => openModal('hs-slide-down-animation-modal')}
          className="relative w-14 h-14 rounded-full flex items-center justify-center shadow-lg hover:scale-110 transition duration-300"
          style={{ backgroundColor: themeColor, color: textColor }}
        >
          <FontAwesomeIcon icon={faStar} size="lg" />
        </button>
        <div
          className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap"
          style={{ backgroundColor: themeColor, color: textColor }}
        >
          Reserve
        </div>
      </div>
      <style>
        {`
          @keyframes slowPing {
            75%, 100% {
              transform: scale(2);
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default FloatingActionButtons;