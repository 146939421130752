import { useState } from "react";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { sendEnquiry } from "../../utils/properties";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  productId: string;
  user?: {
    name: string;
    phoneNumber: string;
    email: string;
  };
}
const EMPTY = "";

export default function FeatureEnquiryModal(props: Props) {
  const { isOpen, onClose, productId, user } = props;
  const [busy, busySet] = useState<boolean>(false);
  const [name, nameSet] = useState<string>(user?.name || EMPTY);
  const [phoneNumber, phoneNumberSet] = useState<string>(user?.phoneNumber || EMPTY);
  const [email, emailSet] = useState<string>(user?.email || EMPTY);

  const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  const clearForm = () => {
    busySet(false);
    nameSet(EMPTY);
    phoneNumberSet(EMPTY);
    emailSet(EMPTY);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    busySet(true);

    const data = { name, phoneNumber, email, productId };

    await sendEnquiry(data)
      .then((result: any) => {
        const CLIENT_ERROR = 400;
        const OK = "OK";
        if (result?.response?.status === CLIENT_ERROR) {
          const message = result.response.data.message;
          alert(`AN ERROR OCCURRED\n\n${message}`);
        }
        if (result?.message && result?.message?.toUpperCase() === OK) {
          clearForm();
          onClose();
        }
        busySet(false);
      })
      .catch((error: any) => {
        console.error("Error when submitting form", error);
        alert(error);
        busySet(false);
      });
  };

  const updateField = (event: any, setFn: any) => setFn(event.target.value || "");

  const inputStyle = {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid black",
    boxSizing: "border-box" as "border-box",
  };

  return isOpen ? (
    <div className="modal-backdrop" style={{ 
      position: "fixed", 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0, 
      backgroundColor: "rgba(0,0,0,0.5)", 
      display: "flex", 
      alignItems: "center", 
      justifyContent: "center" 
    }}>
      <div className="modal-content" style={{ 
        position: "relative", 
        background: "white", 
        padding: "20px", 
        borderRadius: "5px", 
        width: "400px",
        maxWidth: "90%",
        boxSizing: "border-box",
        display: "flex", 
        flexDirection: "column" 
      }}>
        <span 
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
          fontSize: "20px",
          fontWeight: "bold",
          transition: "transform 0.2s", // Add transition for smooth effect
          display: "inline-block", // Needed for transform to work on inline elements
        }}
        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        &times;
      </span>
        <p style={{marginBottom: "10px"}}>For More Details Contact Us</p>
        <form onSubmit={handleSubmit} style={{ 
          display: "flex", 
          flexDirection: "column", 
          width: "100%"
        }}>
          <fieldset disabled={busy} style={{ 
            display: "flex", 
            flexDirection: "column", 
            width: "100%",
            border: "none",
            padding: 0,
            margin: 0
          }}>
            <div style={{ 
              display: "flex", 
              justifyContent: "space-between",
              marginBottom: "10px" 
            }}>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(event) => updateField(event, nameSet)}
                placeholder="Name*"
                required
                style={{
                  ...inputStyle,
                  width: "48%"
                }}
              />
              <input
                type="tel"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(event) => updateField(event, phoneNumberSet)}
                placeholder="Phone*"
                pattern="[0]{1}[0-9]{10}"
                title="11 Digits eg 07851234567"
                required
                style={{
                  ...inputStyle,
                  width: "48%"
                }}
              />
            </div>
            <div style={{ 
              display: "flex", 
              justifyContent: "space-between",
              marginBottom: "10px" 
            }}>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(event) => updateField(event, emailSet)}
                placeholder="Email*"
                required
                style={{
                  ...inputStyle,
                  width: "65%"
                }}
              />
              <button
                type="submit"
                style={{
                  width: "30%",
                  background: themes.color1,
                  color: themes.txtColor,
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  transition: "background 0.3s ease",
                  boxSizing: "border-box"
                }}
                onMouseOver={(e) => e.currentTarget.style.background = themes.color2}
                onMouseOut={(e) => e.currentTarget.style.background = themes.color1}
              >
                Submit
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  ) : null;
}