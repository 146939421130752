import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useInView } from 'react-intersection-observer';
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import JAvatar from '../images/JAvatar.jpeg'; 
import placeholderImage from '../images/photo-coming-soon-300x300-1-square.jpg'; 
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import FloatingActionButtons from '../pages/components/FloatingActionButtons';
import FinancialSection from '../pages/components/FinancialSection';
import ComparablesSection from '../pages/components/ComparablesSection';
import LocalAreaInfoSection from '../pages/components/LocalAreaInformation'
import AdvertisingCarousel from '../pages/components/AdvertisingCarousel';
import {
  faCheck,
  faFilePdf,
  faShareAlt,
  faCopy,
  faEnvelope,
  faComputerMouse
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  checkout,
  getProperty,
  numberFormat,
  orderProperty,
  postMakeAnOffer,
  PropertyExtraResult,
} from "../utils/properties";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import ImageSlider from "../components/property/Slider";

const PropertyShareIcons = () => {
  const [shareExpanded, setShareExpanded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  // Toggle Share Options
  const toggleShareOptions = () => {
    setShareExpanded(!shareExpanded);
  };

  // Handle Copy Link
  const handleCopyLink = () => {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    setModalVisible(true);
    setTimeout(() => setModalVisible(false), 3000); // Hide the modal after 3 seconds
  };

  // Handle WhatsApp Share
  const handleWhatsAppShare = () => {
    const link = window.location.href;
    window.open(`https://wa.me/?text=${encodeURIComponent(link)}`, '_blank');
  };

  // Handle Email Share
  const handleEmailShare = () => {
    const link = window.location.href;
    window.location.href = `mailto:?subject=Check out this property&body=${encodeURIComponent(link)}`;
  };

  // Auto-Close after 20 seconds
  useEffect(() => {
    if (shareExpanded) {
      const timer = setTimeout(() => {
        setShareExpanded(false);
      }, 20000); // 20 seconds
      return () => clearTimeout(timer);
    }
  }, [shareExpanded]);

  // Common icon style
  const iconStyle = {
    transition: 'transform 0.2s',
    fontSize: '1.5rem',
    marginLeft: '8px',
  };

  return (
    <>
      <div className="flex justify-end items-center mt-8 relative">
        {/* PDF and Share Icons Container */}
        <div className="flex">
          {/* PDF Icon */}
          <button
            onClick={() => window.print()}
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            style={{
              ...iconStyle,
              marginLeft: '0', // Remove margin left for the first icon
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <FontAwesomeIcon icon={faFilePdf} />
          </button>

          {/* Divider */}
          <div className="mx-4 h-6 border-l border-gray-300"></div>

          {/* Share Icon */}
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            style={{
              ...iconStyle,
              marginLeft: '0', // Keep this aligned with the PDF icon
            }}
            onClick={toggleShareOptions}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <FontAwesomeIcon icon={faShareAlt} />
          </button>
        </div>

        {/* Share Options */}
        {shareExpanded && (
          <div className="flex items-center ml-4">
            {/* Divider */}
            <div className="mx-4 h-6 border-l border-gray-300"></div>

            {/* Copy Link Icon */}
            <button
              className="text-blue-500 hover:text-blue-700 focus:outline-none"
              style={iconStyle}
              onClick={handleCopyLink}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>

            {/* Divider */}
            <div className="mx-4 h-6 border-l border-gray-300"></div>

            {/* WhatsApp Icon */}
            <button
              className="text-blue-500 hover:text-blue-700 focus:outline-none"
              style={iconStyle}
              onClick={handleWhatsAppShare}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </button>

            {/* Divider */}
            <div className="mx-4 h-6 border-l border-gray-300"></div>

            {/* Email Icon */}
            <button
              className="text-blue-500 hover:text-blue-700 focus:outline-none"
              style={iconStyle}
              onClick={handleEmailShare}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </button>
          </div>
        )}
      </div>

      {/* Modal for Link Copied */}
      {modalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl" style={{backgroundColor: "white"}}>
            <h2 className="text-xl font-bold mb-4">Link Copied</h2>
            <p>This properties link has been copied to your clipboard</p>
          </div>
        </div>
      )}
    </>
  );
};

function Property() {
  const [property, setProperty] = useState<PropertyExtraResult>();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [message, setMessage] = useState("");
  const router = useParams();
  const path = useLocation();
  const getToken = useTokenStore((state) => state.token);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const { ref: buttonRef1, inView: buttonInView1 } = useInView({ triggerOnce: false });
  const { ref: buttonRef2, inView: buttonInView2 } = useInView({ triggerOnce: false });

  interface MenuBarProps {
    shadowed: boolean;
  }
  
  const MenuBar: React.FC<MenuBarProps> = ({ shadowed }) => {
    return (
      <div className={`your-existing-menu-classes ${shadowed ? 'shadow-lg' : ''}`}>
        {/* Your menu bar content */}
      </div>
    );
  };

  // Update the ref type
  const descriptionRef = useRef<HTMLPreElement | null>(null);

  // New state for scroll indicator
  const [isScrollable, setIsScrollable] = useState(false);

  // Updated useEffect for scroll detection
  useEffect(() => {
    const checkScrollable = () => {
      if (descriptionRef.current) {
        setIsScrollable(
          descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight
        );
      }
    };

    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => window.removeEventListener('resize', checkScrollable);
  }, [property]);
  

  function formatNumber(value: number | undefined): string {
    if (value === undefined) return ""; // Handle undefined case
    return value.toLocaleString(); // This uses the browser's locale to add commas appropriately
  }
  
  const [formattedPrice, setFormattedPrice] = useState("");

    useEffect(() => {
      if (property?.results.price !== undefined) {
        setFormattedPrice(formatNumber(property.results.price));
      }
    }, [property?.results.price]); // Update formatted price when the property changes


  const reservePropertySuccess = async () => {
    const response = await orderProperty({
      connectedTo: router.id as string,
      paymentStatus: "success",
      token: getToken!,
    });

    if (response.status === 201) {
      setSuccessPopup(true);
      setMessage(response.message);
    }
    if (response.status !== 201) {
      setErrorPopup(true);
      setMessage(response.message);
    }
  };

  const reservePropertyFailure = async () => {
    const response = await orderProperty({
      connectedTo: router.id as string,
      paymentStatus: "failure",
      token: getToken!,
    });
    if (response.status !== 201) {
      setErrorPopup(true);
      setMessage(response.message);
    }
  };

  
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  useEffect(() => {
    if (path.pathname.split("/").pop() === "success") {
      reservePropertySuccess();
      setTimeout(() => {
        navigate(`/listing/${router.id}`);
        setSuccessPopup(false);
        setErrorPopup(false);
      }, 5000);
    }
    if (path.pathname.split("/").pop() === "failure") {
      reservePropertyFailure();
      setTimeout(() => {
        navigate(`/listing/${router.id}`);
        setErrorPopup(false);
        setSuccessPopup(false);
      }, 5000);
    }

    // submitCheckout();

    const fetchProperty = async () => {
      const pr = await getProperty(router.id as string)
    };
    fetchProperty();
  }, []);

  const [paymentLink, setPaymentLink] = useState("");

  const submitCheckout = async () => {
    setSubmitLoading(true);
    try {
      let response = await checkout({
        id: router.id as string,
        url: `trustbricks.co.uk/properties/${router.id}`,
        token: getToken!,
      });
  
      setPaymentLink(response.session.url);
      return response.session.url;
    } finally {
      setSubmitLoading(false);
    }
  };

  const [offer, setOffer] = useState<string>("");

  const submitOffer = async () => {
    setSubmitLoading(true);
    let response = await postMakeAnOffer({
      connectedTo: router.id as string,
      offer: offer,
      propertyLink: window.location.href,
      token: getToken!,
    });
    setSubmitLoading(false);
    setSuccessPopup(true);
    setMessage(response.message);
    setOffer("");
    closeBtnRef.current?.click();
  };

  const closeBtnRef = useRef<HTMLButtonElement>(null);

  const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);
  

  //Clean string
  function cleanString(input: string): string {
    const unwantedSymbols = ['¬', '∑']; // Add more symbols as needed
    let output = input;
    unwantedSymbols.forEach(symbol => {
        output = output.replace(new RegExp(symbol, 'g'), ''); // Replace each symbol with an empty string
    });
    return output;
  }

  function updateCalculatedValues(arg0: PropertyExtraResult) {
    throw new Error("Function not implemented.");
  }

  // Authentication check
  useEffect(() => {
    if (!getToken) {
      navigate("/login");
    } else {
      setIsAuthenticated(true);
    }
  }, [getToken, navigate]);

  // Fetch property data
  useEffect(() => {
    if (isAuthenticated) {
      const fetchProperty = async () => {
        try {
          const pr = await getProperty(router.id as string);
          setProperty(pr);
        } catch (error) {
          console.error("Error fetching property:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchProperty();
    }
  }, [router.id, isAuthenticated]);

  if (!isAuthenticated) {
    return null; // or a loading spinner
  }

  if (loading) {
    return <div>Loading...</div>;
  }


return (
  <>
    {loading && (
      <div className="h-screen flex top-0 bottom-0 m-auto justify-center ">
        <div
          className="align-middle animate-spin inline-block w-8 h-8 border-[2px] border-primarycolor border-t-transparent text-secondarycolor rounded-full"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )}
    {!loading && (
      <div className="Properties">
        <Helmet>
          <title>Properties</title>
        </Helmet>
        <MenuBar shadowed={true} />

        {/* <==============above banner=================> */}
        
        <div className="container mx-auto p-4">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 pt-10 mt-20">
            <div className="lg:col-span-2 border-gray-300 border bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition duration-150 ease-in-out">
              <div className="pb-5">
              {property?.results.images && property.results.images.length > 0 ? (
                <ImageSlider images={property.results.images} />
              ) : (
                <div className="w-full h-64 bg-[#F3F3F3] flex items-center justify-center">
                  <img 
                    src={placeholderImage} 
                    alt="Photo coming soon" 
                    className="max-w-full max-h-full object-contain"
                  />
                </div>
              )}
                {/* Address and Price */}
                <div className="mt-4 text-left">
                  <h1 className="text-2xl lg:text-3xl font-bold mb-2">
                    {property?.results.bedrooms} Bed Property
                  </h1>
                  
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
                    <span className="text-lg sm:text-xl lg:text-2xl break-words max-w-full sm:max-w-[70%]">
                      {property?.results.fullAddress}
                    </span>
                    
                    <span className="text-2xl lg:text-3xl font-bold">
                      £{numberFormat(property?.results.price as unknown as number)}
                    </span>
                  </div>
                </div>
          
                {/* PDF and Share Icons */}
                <PropertyShareIcons />
              </div>
            </div>

    {/* Right Side: Enquire, WhatsApp, Offer/Reserve Buttons, Property Info, and Property Description (1/3 width) */}
    
  
    <div className="flex flex-col space-y-4">
    <div className="relative w-full" style={{ 
            backgroundImage: `url(${backgroundPattern})`, 
            backgroundRepeat: 'repeat', 
            backgroundSize: '50px 50px' 
          }}>
      
      {/* Make Offer and Reserve */}
        <div id="original-buttons" className="bg-white border-gray-300 border rounded-lg shadow-lg p-8 hover:shadow-xl transition duration-150 ease-in-out">
          <div
            ref={buttonRef1}
            className={`py-2.5 w-full max-w-xs mx-auto rounded-full text-white-f2f9fa hover:bg-white-f2f9fa hover:text-white-f2f9fa transition ease-in-out delay-150 hover:scale-110 duration-300 ${
              buttonInView1 ? 'pulse' : ''
            } mb-4`}
            style={{ background: getThemes && getThemes.color2 }}
            data-hs-overlay="#hs-slide-down-animation-modal1"
          >
            Make an Offer
          </div>
          <div
            ref={buttonRef2}
            className={`py-2.5 w-full max-w-xs mx-auto rounded-full text-white-f2f9fa hover:bg-white-f2f9fa hover:text-white-f2f9fa transition ease-in-out delay-150 hover:scale-110 duration-300 ${
              buttonInView2 ? 'pulse' : ''
            }`}
            style={{ background: getThemes && getThemes.color2 }}
            data-hs-overlay="#hs-slide-down-animation-modal"
          >
            Reserve
          </div>
        </div>
        </div>

      {/* Right side: Property Description (1/3 width on large screens) */}
      <div className="p-2 bg-white border-gray-300 border rounded-lg shadow-lg shadow-lg hover:shadow-xl transition duration-150 ease-in-out" style={{backgroundColor: "white" }}>
      <h4 className="text-xl border-b border-gray-300 font-semibold mt-2 pb-4 pt-4 text-center">
        Property Description
      </h4>
      <div className="relative">
        <pre 
          ref={descriptionRef} 
          className="pt-4 font-poppins text-xs max-w-full textWrap overflow-y-auto max-h-96"
        >
          {property?.results.propertyDescription}
        </pre>
        {isScrollable && (
          <div className="absolute -bottom-12 right-0 p-2 animate-bounce bg-white bg-opacity-75 rounded-tl-lg">
            <FontAwesomeIcon icon={faComputerMouse} />
          </div>
        )}
      </div>
      {property?.results.benefits && property?.results.benefits.length > 0 && (
        <div className="mt-4">
          {property?.results.benefits.map((ele, index) => (
            <h4 className="align-middle gap-x-2 items-center flex" style={{ color: getThemes.txtColor }} key={index}>
              <FontAwesomeIcon icon={faCheck} />
              {ele}
            </h4>
              ))}
            </div>
          )}
        </div>
            </div>
          </div>
        </div>


        {/* Modals for Payment and Offer */}
          <div id="hs-slide-down-animation-modal" className={`hs-overlay ${successPopup || errorPopup ? "open" : "hidden"} w-full h-full fixed top-0 left-0 z-[99999] flex items-center justify-center`}>
            <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full mx-3 sm:mx-auto">
              {!successPopup && !errorPopup && (
                <div className="flex flex-col bg-white-f2f9fa border border-black shadow-sm rounded-xl overflow-hidden">
                  <div className="relative w-full" style={{ backgroundImage: `url(${backgroundPattern})`, backgroundRepeat: 'repeat', backgroundSize: '50px 50px' }}>
                    <div className="flex justify-between items-center py-3 px-4 border-b" style={{ backgroundColor: "white" }}>
                      <h3 className="font-bold">
                        Payment Confirmation
                      </h3>
                      <button 
                        type="button" 
                        data-hs-overlay="#hs-slide-down-animation-modal" 
                        onClick={() => setOfferPopup(false)}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          cursor: "pointer",
                          fontSize: "20px",
                          fontWeight: "bold",
                          transition: "transform 0.2s", // Add transition for smooth effect
                          display: "inline-block", // Needed for transform to work on inline elements
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                      >
                        <span className="sr-only">Close</span>
                        <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                      </button>
                    </div>
                    <div className="p-4 overflow-y-auto">
                      <p className="mt-1 font-medium">
                        You wish to reserve this property
                      </p>
                    </div>
                    <div className="px-4 overflow-y-auto">
                      <div className="flex flex-wrap border p-1 mb-4 rounded-md" style={{ backgroundColor: "white", borderColor: "black" }}>
                        <div className="w-1/4">
                          <img 
                            className="rounded mr-4 object-cover" 
                            src={property?.results.images && property?.results.images.length > 0 ? property.results.images[0]?.url : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"} 
                            alt="Property Thumbnail" 
                            style={{ width: '140px', height: '80px', objectFit: 'cover' }} 
                          />
                        </div>
                        <div className="w-3/4 px-3 flex justify-center items-center">
                          <h3 className="text-xl text-center">
                            {property?.results.fullAddress}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="px-5 pb-3">
                      <p className="text-black-1c2023 text-sm">
                        <b>Sourcing Fee:</b> Sourcing Fee: The standard sourcing fee for each deal is 2.4% of the purchase price (incl. UK VAT) with a minimum of £3,600 (incl. UK VAT). Reserving this deal simply requires a fully refundable upfront reservation fee, with the balance paid later in stages if you proceed with the purchase.
                      </p>
                    </div>
                    <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t" style={{ backgroundColor: "white" }}>
                    <button
                      className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium text-white-f2f9fa shadow-sm align-middle transition-all text-sm"
                      style={{
                        background: themes.color1,
                        transition: "background 0.3s ease",
                      }}
                      onMouseOver={(e) => e.currentTarget.style.background = themes.color2}
                      onMouseOut={(e) => e.currentTarget.style.background = themes.color1}
                      data-hs-overlay="#hs-static-backdrop-modal"
                      onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        if (!paymentLink) {
                          const link = await submitCheckout();
                          if (link) {
                            window.location.href = link;
                          }
                        } else {
                          window.location.href = paymentLink;
                        }
                      }}
                      disabled={submitLoading}
                    >
                      {submitLoading ? (
                        <span className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>
                      ) : (
                        <>Pay Now (£{property?.results.reservationPrice})</>
                      )}
                    </button>
                    </div>
                  </div>
                </div>
              )}
              {(successPopup || errorPopup) && (
                <div>
                  <div className="">
                    <div className="flex flex-col bg-white-f2f9fa border shadow-sm rounded-xl">
                      <div className="p-4 m-auto">
                        <img src={`https://irimiaionut.github.io/Animated-Icons-SVG/resources/essential/${successPopup ? "success.svg" : "deny.svg"}`} className="m-auto w-36" alt="" />
                        <h3 className="text-2xl text-primarycolor font-medium text-center">
                          {property?.results.fullAddress}
                        </h3>
                        <p className="mt-1 text-primarycolor font-medium text-lg text-center">
                          {message}
                        </p>
                      </div>

                      <div className="m-auto flex justify-end items-center gap-x-2 py-3 px-4">
                        <button 
                          type="button" 
                          className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white-f2f9fa text-gray-700 shadow-sm align-middle hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                          onClick={() => {
                            navigate(`/listing/${router.id}`);
                            setSuccessPopup(false);
                            setErrorPopup(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>



        {/* Modals for Make an Offer */}
          <div id="hs-slide-down-animation-modal1" className={`hs-overlay ${offerPopup ? "open" : "hidden"} w-full h-full fixed top-0 left-0 z-[99999] flex items-center justify-center`}>
            <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full mx-3 sm:mx-auto">
              <div className="flex flex-col bg-white-f2f9fa border border-black shadow-sm rounded-xl overflow-hidden">
                <div className="relative w-full" style={{ 
                  backgroundImage: `url(${backgroundPattern})`, 
                  backgroundRepeat: 'repeat', 
                  backgroundSize: '50px 50px' 
                }}>
                  <div className="flex justify-between items-center py-3 px-4 border-b" style={{backgroundColor: "white" }}>
                    <h3 className="font-bold">
                      Make an offer, what have you got to lose?
                    </h3>
                    <button 
                      type="button" 
                      data-hs-overlay="#hs-slide-down-animation-modal1" 
                      onClick={() => setOfferPopup(false)} 
                      ref={closeBtnRef}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                        fontSize: "20px",
                        fontWeight: "bold",
                        transition: "transform 0.2s", // Add transition for smooth effect
                        display: "inline-block", // Needed for transform to work on inline elements
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    >
                      <span className="sr-only">Close</span>
                      <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                      </svg>
                    </button>
                  </div>
                  <div className="p-2 overflow-y-auto">
                    {/* Add any necessary content here */}
                  </div>
                  <div className="px-4 overflow-y-auto">
                    <div className="flex flex-wrap border p-1 mb-4 rounded-md" style={{ backgroundColor: "white", borderColor: "black" }}>
                      <div className="w-1/4">
                        <img 
                          className="rounded mr-4 object-cover" 
                          src={property?.results.images && property?.results.images.length > 0 ? property.results.images[0]?.url : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"} 
                          alt="Property Thumbnail" 
                          style={{ width: '140px', height: '80px', objectFit: 'cover' }} 
                        />
                      </div>
                      <div className="w-3/4 px-3 flex justify-center items-center">
                        <h3 className="text-xl text-center">
                          {property?.results.fullAddress}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="px-5 mb-3">
                    <h3 className="text-xl">Your Offer</h3>
                    <textarea name="" rows={5} placeholder="Please enter your offer here" className="mt-3 p-3 text-black-1c2023 border border-gray-500 rounded-md w-full resize-none" value={offer} onChange={(e) => setOffer(e.target.value)}></textarea>
                  </div>

                  <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t" style={{backgroundColor: "white" }}>
                    {!submitLoading && (
                      <button 
                        type="button" 
                        className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium text-white-f2f9fa shadow-sm align-middle transition-all text-sm"
                        style={{
                          background: themes.color1,
                          transition: "background 0.3s ease"
                        }}
                        onMouseOver={(e) => e.currentTarget.style.background = themes.color2}
                        onMouseOut={(e) => e.currentTarget.style.background = themes.color1}
                        onClick={() => submitOffer()}>
                        Submit
                      </button>
                    )}
                    {submitLoading && (
                      <button 
                        type="button" 
                        className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium text-white-f2f9fa shadow-sm align-middle transition-all text-sm"
                        style={{
                          background: getThemes && getThemes.color1,
                          cursor: "default"
                        }}
                        disabled>
                        Submitting...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <==============New Section=================> */}
          
          <div className="relative w-full" style={{ 
            backgroundImage: `url(${backgroundPattern})`, 
            backgroundRepeat: 'repeat', 
            backgroundSize: '75px 75px' 
          }}>
          {property && getThemes && (<FinancialSection property={property} getThemes={getThemes} />
          )}
          </div>

          {/* <==============End of New Section=================> */}


          {/* <==============below fold without side/bg=================> */}


          <ComparablesSection property={property} getThemes={getThemes} />

          {/* <==============below fold with side=================> */}
          <div className="relative w-full" style={{ 
            backgroundImage: `url(${backgroundPattern})`, 
            backgroundRepeat: 'repeat', 
            backgroundSize: '75px 75px' 
          }}>
            <LocalAreaInfoSection property={property} getThemes={getThemes} />
          </div>

          {/* <==============below fold without side/bg=================> */}
          {!loading && property && (
            <div className="Properties">
              
              {/* Insert the property region into the AdvertisingCarousel */}
              <AdvertisingCarousel region={property.results.region} limit={6} />
            </div>
          )}
          {/* <==============below fold without side/bg=================> */}
          
          <div className="relative w-full" style={{ 
            backgroundImage: `url(${backgroundPattern})`, 
            backgroundRepeat: 'repeat', 
            backgroundSize: '75px 75px' 
          }}>
                  <div className="w-full py-4"
                  style={{
                    margin: "0 auto", // Center the section
                    padding: "20px", // Consistent padding with other sections
                    marginBottom: "10px", // 10px space at the bottom
                  }}
                >
                    <div className="flex max-w-7xl m-auto py-5 lg:p-0 bg-white rounded-lg shadow-lg p-4" style={{ backgroundColor: "white" }}>
                      <div className="w-full text-left bg-white border-gray-300 border shadow-sm rounded-xl font-roboto p-5">
                        <div className="rounded-t-xl mb-3">
                          <h3
                            className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left">
                            Disclaimer
                          </h3>
                        </div>
                        <p>
                        
                          This is for your information only – you shouldn't view this as
                          legal advice, tax advice, investment advice, or any advice at
                          all. This information does not constitute a solicitation,
                          recommendation, endorsement in this or in any other
                          jurisdiction. While we've tried to make sure this information
                          is accurate and up to date, things can change, so it shouldn't
                          be viewed as totally comprehensive. We always recommends you
                          seek out independent advice before making any investment
                          decisions.
                        </p>
                        <br />
                        <p>
                          With respect to gross yield & rental income, these values are
                          either provided by our partners or provided by comparing
                          similar properties within the same geographical location.
                          Gross yield is calculated using the standard industry formula
                          by taking the annual rental income of the property and
                          dividing it by the purchase price of the property. Any
                          information pertaining to the EPC has been retrieved via the
                          UK government public database of EPC certificates.
                        </p>
                        <p>
                          Stock listed may on occasion be sold or withdrawn by Vendors
                          at short notice, and thus be no longer available.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <FloatingActionButtons 
                  themeColor={(getThemes && getThemes.color2) || '#default-color'} 
                  textColor={(getThemes && getThemes.txtColor) || '#default-text-color'} 
                />
                {/* Floating WhatsApp Integration */}
                <FloatingWhatsApp
                phoneNumber="07457411594"
                accountName="Jamie Hart"
                avatar={JAvatar} 
                statusMessage="Typically replies within 1 hour"
                chatMessage="Hello Jamie from the team will be your direct contact. You can also reach on 020 3985 2580"
                placeholder="Type a message.."
                messageDelay={2}
                darkMode={false}
                allowClickAway
                allowEsc
                chatboxHeight={320}
                notification
                notificationDelay={60}
                notificationSound
                notificationLoop={2}
                onClick={() => console.log('Chat box opened')}
                onSubmit={(event, value) => console.log('Message sent:', value)}
                onClose={() => console.log('Chat box closed')}
              />
                </div>
              )}
            </>
          );
        }

        export default Property;

                  